import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { AuthContext } from 'contexts/AuthContext';
import { useModal, useDrawer } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import PrecoModal from './Modals/PrecoModal';
import ProdutoModal from './Modals/ProdutoModal';
import Filter from './filter';
import mock from './mock';

const Produtos = () => {
  const rotina = 'Produto';
  const titulo = 'Produtos';
  const navigate = useNavigate();
  const { openDrawer } = useDrawer();
  const { openModal, closeModal } = useModal();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { deleteGrid, exportPdf, exportXls } = useContext(GridContext);
  const {
    produtos,
    getProdutos,
    getLoading,
    putProdutoStatus,
    postProdutoCopia,
    postProdutoPreco,
  } = useContext(ProdutosContext);
  const { control, getValues, reset } = useForm({
    defaultValues: produtos?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getProdutos({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      show: !user?.permissoes?.block_inclui_produto,
      action: () => navigate('/app/Produtos/Manutencao'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: produtos?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: produtos?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={produtos?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Impressão de Etiquetas',
      icon: 'sell',
      show: !user?.permissoes?.block_etiqueta_produto,
      action: (selected) => {
        if (Boolean(selected?.length)) {
          navigate('/app/Produtos/Etiquetas', {
            state: { produtos: selected?.map((s) => s?.id) },
          });
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Alterar Preço',
      icon: 'price_change',
      show: !user?.permissoes?.block_altera_produto,
      action: (selected) =>
        openModal(
          <PrecoModal
            onSubmit={({ preco }) => {
              const data = {
                rotina,
                filter: getValues(),
                ids: selected?.map((m) => m?.id),
                preco,
              };
              const cb = () => {
                closeModal();
                loadGrid();
              };
              postProdutoPreco({ data, cb });
            }}
            sum={Boolean(selected?.length) ? selected?.length : produtos?.total}
          />
        ),
    },
  ];

  const rowOptions = [
    {
      name: 'Análise',
      icon: 'query_stats',
      show: () => !user?.permissoes?.block_analise_produto,
      action: ({ row }) => navigate(`/app/Produtos/Indicadores/${row?.id}`),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      show: () => !user?.permissoes?.block_detalhe_produto,
      action: ({ row }) => navigate(`/app/Produtos/Detalhes/${row?.id}`),
    },
    {
      name: 'Editar',
      icon: 'edit',
      show: () => !user?.permissoes?.block_altera_produto,
      action: ({ row }) =>
        navigate('/app/Produtos/Manutencao', { state: { id: row?.id } }),
    },
    {
      name: 'Excluir',
      icon: 'delete',
      show: () => !user?.permissoes?.block_exclui_produto,
      action: ({ row }) =>
        openModal(
          <DeleteModal
            onSubmit={() =>
              deleteGrid({
                params: { rotina, id: row?.id },
                cb: () => {
                  loadGrid();
                  closeModal();
                },
              })
            }
          />
        ),
    },
    {
      name: 'Ativar',
      icon: 'key',
      menu: true,
      show: ({ row }) =>
        row?.ativo !== 'SIM' && !user?.permissoes?.block_ativa_produto,
      action: ({ row }) => putProdutoStatus({ id: row?.id, cb: loadGrid }),
    },
    {
      name: 'Inativar',
      icon: 'key_off',
      menu: true,
      show: ({ row }) =>
        row?.ativo === 'SIM' && !user?.permissoes?.block_inativa_produto,
      action: ({ row }) => putProdutoStatus({ id: row?.id, cb: loadGrid }),
    },
    {
      name: 'Duplicar',
      icon: 'file_copy',
      menu: true,
      show: () => !user?.permissoes?.block_duplica_produto,
      action: ({ row }) =>
        postProdutoCopia({ data: { id: row?.id }, cb: loadGrid }),
    },
  ];

  const tabelas = [
    {
      name: 'Classificação Fiscal',
      route: 'Cfiscal',
    },
    {
      name: 'Unidade de Medida',
      route: 'UnidadeMedida',
    },
    {
      name: 'Origem Tributária',
      route: 'OrigemTributaria',
    },
    {
      name: 'Local de Estoque',
      route: 'LocalEstoque',
    },
    {
      name: 'Modelo',
      route: 'Modelo',
    },
    {
      name: 'Marca',
      route: 'Marca',
    },
    {
      name: 'Grupo',
      route: 'GrupoProduto',
    },
    {
      name: 'Linha',
      route: 'LinhaProduto',
    },
    {
      name: 'ANP',
      route: 'Anp',
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={produtos?.colunas}
        tabelas={!user?.permissoes?.block_tabelas && tabelas}
      />
      <Card>
        <Grid
          grid={produtos}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={produtos} control={control} drops={drops} />
          }
          onCellClick={({ field, row }) => {
            if (field === 'descricao') {
              openDrawer(<ProdutoModal produto={row} />, row?.descricao);
            }
          }}
        />
      </Card>
    </Container>
  );
};

export default Produtos;
