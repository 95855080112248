import React, { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { dropTipoConta, dropBoolean } from 'utils/drops';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';

const Manutenção = () => {
  const rotina = 'Contas';
  const defaultValues = {
    banco_id: null,
    agencia: '',
    conta: '',
    descricao: '',
    tipo: null,
    entidade_id: null,
    planoconta_id: null,
    moeda_id: null,
    fluxo: null,
    conciliacao: null,
    contabiliza: null,
    limitedesc: '',
    limitecob: '',
    ativo: null,
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { drops } = useContext(DropsContext);
  const { postLoading, postGrid } = useContext(GridContext);
  const { control, handleSubmit, setValue } = useForm({ defaultValues });

  useEffect(() => {
    if (state?.id) {
      setValue('id', state?.id);
      setValue('banco_id', state?.banco_id || null);
      setValue('agencia', state?.agencia || '');
      setValue('conta', state?.conta || '');
      setValue('descricao', state?.descricao || '');
      setValue('tipo', state?.tipo || null);
      setValue('entidade_id', state?.entidade_id || null);
      setValue('planoconta_id', state?.planoconta_id || null);
      setValue('moeda_id', state?.moeda_id || null);
      setValue('fluxo', state?.fluxo || null);
      setValue('conciliacao', state?.conciliacao || null);
      setValue('contabiliza', state?.contabiliza || null);
      setValue('limitedesc', state?.limitedesc || '');
      setValue('limitecob', state?.limitecob || '');
      setValue('ativo', state?.ativo || null);
    }
  }, []);

  const onSubmit = (data) =>
    postGrid({
      data: { data, rotina },
      cb: () => navigate(-1),
    });

  return (
    <Container>
      <Header titulo={(Boolean(state) ? 'Editar' : 'Cadastrar') + ' Conta'} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="banco_id"
              control={control}
              label="Banco"
              options={drops?.EntidadeTipo?.filter(
                (f) => f?.tipo === 'INSTITUICAO FINANCEIRA'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="agencia" control={control} label="Agência" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="conta" control={control} label="Conta" />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Input name="descricao" control={control} label="Descrição" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="tipo"
              control={control}
              label="Tipo"
              options={dropTipoConta}
            />
          </Grid>
          <Grid item xs={12}>
            <VirtualDrop
              name="entidade_id"
              control={control}
              label="Responsável"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="planoconta_id"
              control={control}
              label="Plano de Contas"
              options={drops?.Planoconta?.filter(
                (p) => p?.operacional === 'SIM'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="moeda_id"
              control={control}
              label="Moeda"
              options={drops?.Moeda}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="fluxo"
              control={control}
              label="Fluxo"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="conciliacao"
              control={control}
              label="Conciliação"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="contabiliza"
              control={control}
              label="Contabilização"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="ativo"
              control={control}
              label="Ativo"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="limitedesc"
              control={control}
              label="Limite de Desconto"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="limitecob"
              control={control}
              label="Limite de Cobrança"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Manutenção;
