import { createContext, useState } from 'react';
import api from 'services/api';

export const TributacaoContext = createContext();

export const TributacaoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const getRegras = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Fiscal/Regras/Tributacao', { params });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postRegras = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post('/Fiscal/Regras/Tributacao', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <TributacaoContext.Provider
      value={{ getLoading, getRegras, postRegras, postLoading }}
    >
      {children}
    </TributacaoContext.Provider>
  );
};
