import { useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import { TributacaoContext } from 'contexts/TributaçãoContext';
import { useModal } from 'components/Modals';
import VirtualDrop from 'components/VirtualDrop';
import Input from 'components/Input';
import Button from 'components/Button';

const EditModal = ({ regra = {}, onSubmit }) => {
  const defaultValues = {
    ...regra,
    percentual: regra?.percentual || '',
    situacao_tributaria_id: regra?.situacao_tributaria_id || null,
  };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(TributacaoContext);
  const { drops } = useContext(DropsContext);

  return (
    <>
      <DialogTitle>Editar Regras</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="caption" color="error">
              Atenção! Será aplicada as alterações em todas as regras que estão
              filtradas
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <VirtualDrop
              name="situacao_tributaria_id"
              control={control}
              label="Situação Tributária"
              options={drops?.SituacaoTributaria}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              type="number"
              name="percentual"
              control={control}
              label="% do Imposto"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default EditModal;
