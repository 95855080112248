import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  DataGrid,
  GridActionsCellItem,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import {
  Box,
  Grid,
  LinearProgress,
  Pagination,
  Typography,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { DropsContext } from 'contexts/DropsContext';
import { TributacaoContext } from 'contexts/TributaçãoContext';
import { GridContext } from 'contexts/GridContext';
import { dropBoolean } from 'utils/drops';
import { useModal } from 'components/Modals';
import EditModal from './Modals/EditModal';
import ManutençãoModal from './Modals/ManutençãoModal';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';
import localeText from 'utils/localeText';
import styles from './styles';

const Tributação = () => {
  const rotina = 'Tributacao';
  const titulo = 'Regras de Tributação';
  const defaultValues = {
    tributo_id: null,
    natureza_operacao_id: null,
    filial_id: null,
    uforg_id: null,
    ufdest_id: null,
    cfiscal_id: null,
    produto_id: null,
    origem_tributaria_id: null,
    situacao_tributaria_id: null,
    entidade_id: null,
    atividade_entidade_id: null,
    contribuinte_id: null,
    usofinal: null,
    percentual: '',
  };
  const [rows, setRows] = useState([]);
  const { openModal, closeModal } = useModal();
  const { control, watch } = useForm({ defaultValues });
  const {
    getLoading,
    getRegras,
    postRegras,
    postLoading: pstLoading,
  } = useContext(TributacaoContext);
  const { drops } = useContext(DropsContext);
  const { deleteGrid, deleteLoading, postGrid, postLoading } =
    useContext(GridContext);

  const data = rows?.filter((item) =>
    Object.entries(watch())
      .filter(([chave, valor]) => Boolean(valor))
      .every(([chave, valor]) => item[chave] === valor)
  );

  const loadData = () => {
    getRegras({ cb: setRows });
    closeModal();
  };

  useEffect(() => {
    loadData();
  }, []);

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  const NaturezaCell = ({ row }) => {
    const item = row?.NaturezaOperacao;
    const lines = [{ label: 'Operação', value: item?.operacao }];

    return (
      <Box flex={1} my={1}>
        <Typography mb={1} variant="body1">
          {item?.natureza || ''}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption">
                  {label || ''}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value || ''}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const CfiscalCell = ({ row }) => {
    const item = row?.Cfiscal;
    const lines = [{ label: 'NCM', value: item?.ncm }];

    return (
      <Box flex={1} my={1}>
        <Typography mb={1} variant="body1">
          {item?.descricao || ''}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption">
                  {label || ''}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value || ''}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const AtividadeEntidadeCell = ({ row }) => {
    const item = row?.AtividadeEntidade;
    const lines = [{ label: 'Código', value: item?.codigo?.toString() }];

    return (
      <Box flex={1} my={1}>
        <Typography mb={1} variant="body1">
          {item?.descricao || ''}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption">
                  {label || ''}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value || ''}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const OrigemCell = ({ row }) => {
    const item = row?.OrigemTributarium;
    const lines = [{ label: 'Código', value: item?.codigo?.toString() }];

    return (
      <Box flex={1} my={1}>
        <Typography mb={1} variant="body1">
          {item?.descricao || ''}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption">
                  {label || ''}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value || ''}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const ContribuinteCell = ({ row }) => {
    const item = row?.Contribuinte;
    const lines = [{ label: 'Código', value: item?.codigo?.toString() }];

    return (
      <Box flex={1} my={1}>
        <Typography mb={1} variant="body1">
          {item?.descricao || ''}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption">
                  {label || ''}:{' '}
                </Typography>
                <Typography flex={1} variant="caption">
                  {value || ''}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const PercentuaisCell = ({ row }) => {
    const lines = [
      { label: 'Imposto', value: row?.percentual },
      { label: 'Redução do Imposto', value: row?.reducao_pc },
      { label: 'Diferimento do Imposto', value: row?.diferido_pc },
      { label: 'ICMS Interno', value: row?.icms_interno },
    ];

    return (
      <Box flex={1} my={1}>
        {lines?.map(({ label, value }, i) => (
          <Box key={i?.toString()} display="flex">
            <Typography flex={3} variant="caption">
              {label || ''}:{' '}
            </Typography>
            <Typography flex={2} variant="caption">
              {value || 0}%
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const BaseCell = ({ row }) => {
    const lines = [
      { label: 'Modalidade', value: row?.modalidade },
      { label: 'ICMS', value: row?.bcipi },
      { label: 'IPI', value: row?.bcicms },
      { label: 'Desconto', value: row?.bcdesconto },
      { label: 'Frete', value: row?.bcfrete },
      { label: 'Outros', value: row?.bcoutros },
    ];

    return (
      <Box flex={1} my={1}>
        {lines?.map(({ label, value }, i) => (
          <Box key={i?.toString()} display="flex">
            <Typography flex={3} variant="caption">
              {label || ''}:{' '}
            </Typography>
            <Typography flex={2} variant="caption">
              {value || ''}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const OutrosCell = ({ row }) => {
    const lines = [
      {
        label: 'Situação Tributária',
        value:
          (row?.SituacaoTributarium?.cst || '') +
          ' - ' +
          (row?.SituacaoTributarium?.descricao || ''),
      },
      { label: 'Não Tributado', value: row?.ntributado },
      { label: 'Motivo da Desoneração', value: row?.desoneracao_mot },
      { label: 'Recuperável', value: row?.recuperavel },
      { label: 'Ativo', value: row?.ativo },
    ];

    return (
      <Box flex={1} my={1}>
        {lines?.map(({ label, value }, i) => (
          <Box key={i?.toString()} display="flex">
            <Typography flex={3} variant="caption">
              {label || ''}:{' '}
            </Typography>
            <Typography flex={2} variant="caption">
              {value || ''}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Card title="Filtros">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <VirtualDrop
                  name="tributo_id"
                  label="Tributo"
                  control={control}
                  options={drops?.Tributo}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="natureza_operacao_id"
                  label="Natureza da Operação"
                  control={control}
                  options={drops?.NaturezaOperacao}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="filial_id"
                  label="Filial"
                  control={control}
                  options={drops?.Filial}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="uforg_id"
                  label="UF de Origem"
                  control={control}
                  options={drops?.UF}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="ufdest_id"
                  label="UF de Destino"
                  control={control}
                  options={drops?.UF}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="cfiscal_id"
                  label="Classificação Fiscal"
                  control={control}
                  options={drops?.Cfiscal}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="produto_id"
                  label="Produto"
                  control={control}
                  options={drops?.Produto}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="origem_tributaria_id"
                  label="Origem Tributária"
                  control={control}
                  options={drops?.OrigemTributaria}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="situacao_tributaria_id"
                  label="Situação Tributária"
                  control={control}
                  options={drops?.SituacaoTributaria}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="entidade_id"
                  label="Entidade"
                  control={control}
                  options={drops?.Entidade}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="atividade_entidade_id"
                  label="Atividade Entidade"
                  control={control}
                  options={drops?.AtividadeEntidade}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="contribuinte_id"
                  label="Contribuinte"
                  control={control}
                  options={drops?.Contribuinte}
                />
              </Grid>
              <Grid item xs={12}>
                <VirtualDrop
                  name="usofinal"
                  label="Uso Final"
                  control={control}
                  options={dropBoolean}
                />
              </Grid>
              <Grid item xs={12}>
                <InputMask
                  name="percentual"
                  control={control}
                  label="% do Imposto"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={10} display="flex">
          <Card style={styles?.card}>
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" justifyContent="center" gap={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    openModal(
                      <ManutençãoModal
                        regra={watch()}
                        onSubmit={(data) => postGrid({ data, cb: loadData })}
                      />,
                      90
                    )
                  }
                  startIcon={<Add fontSize="medium" />}
                >
                  Adicionar Regra
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    openModal(
                      <EditModal
                        regra={watch()}
                        onSubmit={(data) => postRegras({ data, cb: loadData })}
                      />,
                      90
                    )
                  }
                  startIcon={<Edit fontSize="medium" />}
                >
                  Editar Regras
                </Button>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={data}
                  columns={[
                    {
                      field: 'actions',
                      type: 'actions',
                      headerName: 'Ações',
                      width: 100,
                      getActions: ({ id, row }) => {
                        return [
                          <GridActionsCellItem
                            icon={<Edit />}
                            label="Editar"
                            onClick={() =>
                              openModal(
                                <ManutençãoModal
                                  regra={row}
                                  onSubmit={(data) =>
                                    postGrid({ data, cb: loadData })
                                  }
                                />,
                                90
                              )
                            }
                          />,
                          <GridActionsCellItem
                            icon={<Delete />}
                            label="Excluir"
                            onClick={() =>
                              openModal(
                                <DeleteModal
                                  onSubmit={() =>
                                    deleteGrid({
                                      params: { rotina, id },
                                      cb: loadData,
                                    })
                                  }
                                />
                              )
                            }
                          />,
                        ];
                      },
                    },
                    {
                      field: 'tributo',
                      headerName: 'Tributo',
                      width: 100,
                      valueGetter: ({ row }) => row?.Tributo?.descricao,
                    },
                    {
                      field: 'natureza',
                      headerName: 'Natureza da Operação',
                      width: 200,
                      renderCell: (props) => <NaturezaCell {...props} />,
                    },
                    {
                      field: 'filial',
                      headerName: 'Filial',
                      width: 300,
                    },
                    {
                      field: 'uforg',
                      headerName: 'UF Origem',
                      width: 100,
                    },
                    {
                      field: 'ufdest',
                      headerName: 'UF Destino',
                      width: 100,
                    },
                    {
                      field: 'cfiscal',
                      headerName: 'Classificação Fiscal',
                      width: 300,
                      renderCell: (props) => <CfiscalCell {...props} />,
                    },
                    {
                      field: 'produto',
                      headerName: 'Produto',
                      width: 300,
                    },
                    {
                      field: 'origem',
                      headerName: 'Origem Tributária',
                      width: 200,
                      renderCell: (props) => <OrigemCell {...props} />,
                    },
                    {
                      field: 'entidade',
                      headerName: 'Entidade',
                      width: 300,
                    },
                    {
                      field: 'atividade_entidade',
                      headerName: 'Atividade da Entidade',
                      width: 300,
                      renderCell: (props) => (
                        <AtividadeEntidadeCell {...props} />
                      ),
                    },
                    {
                      field: 'contribuinte',
                      headerName: 'Contribuinte',
                      width: 200,
                      renderCell: (props) => <ContribuinteCell {...props} />,
                    },
                    {
                      field: 'usofinal',
                      headerName: 'Uso Final',
                      width: 100,
                    },
                    {
                      field: 'percentuais',
                      headerName: 'Percentuais',
                      width: 300,
                      renderCell: (props) => <PercentuaisCell {...props} />,
                    },
                    {
                      field: 'base',
                      headerName: 'Base de Cálculo',
                      width: 300,
                      renderCell: (props) => <BaseCell {...props} />,
                    },
                    {
                      field: 'outros',
                      headerName: 'Outros',
                      width: 300,
                      renderCell: (props) => <OutrosCell {...props} />,
                    },
                  ]}
                  autoHeight
                  pagination
                  pageSize={5}
                  density="compact"
                  disableColumnMenu
                  showCellRightBorder
                  showColumnRightBorder
                  disableSelectionOnClick
                  localeText={localeText}
                  keepNonExistentRowsSelected
                  getRowHeight={() => 'auto'}
                  loading={
                    getLoading || deleteLoading || postLoading || pstLoading
                  }
                  components={{
                    LoadingOverlay: LinearProgress,
                    Pagination: CustomPagination,
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Tributação;
