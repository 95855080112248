import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { DoneAll, InfoOutlined, RemoveDone } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { ProdutosContext } from 'contexts/ProdutosContext';
import Loader from 'components/Loader';
import localeText from 'utils/localeText';
import Blank from 'assets/blank.png';

const ProdutoModal = ({ produto: prd }) => {
  const [tab, setTab] = useState(0);
  const [produto, setProduto] = useState(null);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { getProdutoSaldo, getLoading } = useContext(ProdutosContext);

  const imgProps = {
    width: '100%',
    height: '100%',
    flex: 1,
    borderRadius: '10px',
    boxShadow: 3,
    objectFit: 'contain',
  };

  const itemProps = { item: true, xs: 12, mx: 2, flexDirection: 'column' };

  const chipProps = { position: 'absolute', top: 10, left: 10 };

  const getEstoque = (filial, local) =>
    getURLRelatorio({
      data: { codigo: 'MVE0000001', prd: prd?.id, filial, local },
    });

  useEffect(() => {
    getProdutoSaldo({ id: prd?.id, cb: setProduto });
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  const Column = ({ rows, size = 1 }) => (
    <Box flex={size} display="flex" flexDirection="column" height="100%">
      {rows?.map((m, i) => (
        <Typography key={i?.toString()} variant="caption" fontWeight="bold">
          {m?.label}:{' '}
          <Typography variant="caption">{m?.value || '-'}</Typography>
        </Typography>
      ))}
    </Box>
  );

  const Line = ({ label, value }) =>
    Boolean(value) && (
      <Typography variant="caption" fontWeight="bold">
        {label}: <Typography variant="caption">{value}</Typography>
      </Typography>
    );

  return (
    <Grid container spacing={2}>
      <Grid {...itemProps}>
        <Box display="flex" gap={2} height="100%">
          <Box flex={1} position="relative">
            <Chip
              size="small"
              label={produto?.ativo === 'SIM' ? 'ATIVO' : 'INATIVO'}
              color={produto?.ativo === 'SIM' ? 'primary' : 'error'}
              icon={produto?.ativo === 'SIM' ? <DoneAll /> : <RemoveDone />}
              sx={chipProps}
            />
            <Box
              component="img"
              src={produto?.url || Blank}
              alt="Imagem"
              sx={imgProps}
            />
          </Box>
          <Column
            size={1}
            rows={[
              { label: 'Referência', value: produto?.referencia },
              { label: 'Código', value: produto?.codigo },
              { label: 'Altura', value: produto?.altura },
              { label: 'Largura', value: produto?.largura },
              { label: 'Comprimento', value: produto?.comprimento },
              { label: 'Peso', value: produto?.peso },
              { label: 'Volume', value: produto?.volume },
            ]}
          />
          <Column
            size={2}
            rows={[
              { label: 'Grupo', value: produto?.grupo },
              { label: 'Linha', value: produto?.linha },
              { label: 'Marca', value: produto?.marca },
              { label: 'Modelo', value: produto?.modelo },
            ]}
          />
        </Box>
      </Grid>
      <Grid {...itemProps} display="flex">
        <Line label="NCM" value={produto?.ncm} />
        <Line label="Origem Tributária" value={produto?.origem_tributaria} />
        <Line label="Complemento 1" value={produto?.complemento1} />
        <Line label="Complemento 2" value={produto?.complemento2} />
      </Grid>
      <Grid item xs={12}>
        <Tabs variant="fullWidth" value={tab} onChange={(_, v) => setTab(v)}>
          <Tab value={0} label="Saldo" />
        </Tabs>
      </Grid>
      <Grid {...itemProps} display={tab === 0 ? 'flex' : 'none'}>
        {produto?.estoque?.map((p, i) => {
          const rows = [
            ...p?.locais,
            { disponibilizar: 'NAO', local: 'Reserva', saldo: p?.reserva },
          ];
          return (
            <Box key={i?.toString()} mb={2}>
              <Typography variant="body1" fontWeight="bold" mb={1}>
                {p?.filial}
              </Typography>
              <DataGrid
                rows={rows}
                columns={[
                  {
                    field: 'local',
                    headerName: 'Local',
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                  },
                  {
                    field: 'disponibilizar',
                    headerName: 'Disponível',
                    width: 100,
                    sortable: false,
                    type: 'boolean',
                    valueGetter: ({ value }) => value === 'SIM',
                  },
                  {
                    field: 'saldo',
                    headerName: 'Saldo',
                    width: 150,
                    sortable: false,
                    renderCell: ({ row }) => (
                      <Box display="flex" flex={1} gap={2} alignItems="center">
                        <Typography variant="body2" flex={1}>
                          {row?.saldo || 0} {produto?.um || ''}
                        </Typography>
                        <Tooltip title="Relatório" placement="left">
                          <IconButton
                            size="small"
                            onClick={() =>
                              getEstoque(p?.filial_id, row?.local_id)
                            }
                          >
                            <InfoOutlined fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ),
                  },
                ]}
                hideFooter
                autoHeight
                density="compact"
                getRowId={(row) => JSON.stringify(row)}
                disableSelectionOnClick
                disableColumnMenu
                showCellRightBorder
                showColumnRightBorder
                localeText={localeText}
              />
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ProdutoModal;
